import React from 'react'
import Layout from '../components/layout'
import { Container } from 'theme-ui'

const Vision = () => {
  return (
    <Layout variant='sidebar'>
      <Container variant='layout'>
        <h1>Vision</h1>
      </Container>
    </Layout>
  )
}
export default Vision
